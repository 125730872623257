body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

.MuiBorder-Input {
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  height: 55px !important;
  font-size: 16px !important;
}

.MuiBorder-Input::placeholder {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiBorder-Input:focus {
  border: 2px solid #01B1C3 !important;
}

.dzu-input {
  display: none;
}

.dzu-submitButton {
  color: #fff;
  background-color: #066F88;
  padding: 20px 15px;;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  box-sizing: border-box;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}